import React from "react"

import urlImgBehance from "../images/icon-behance.svg"
import urlImgLinkedin from "../images/icon-linkedin.svg"

const SectionContact = () => (
  <section id="contact" class="bg-gray-400 pt-16 px-5 pb-16 lg:pb-40 bg-primary shadow">
      <div style={{
          margin: `0 auto`,
          maxWidth: 1066
        }}>
        <h2 class="text-2xl lg:text-4xl text-white">Lets talk!</h2>
        <p class="text-white mb-1">Feel free to drop me a line at</p>
        <p class="text-2xl font-bold text-white mb-16"><a href="mailto:hi@kalebjimenez.design">hi@kalebjimenez.design</a></p>
        <p class="flex flex-row m-0">
          <a href="https://www.behance.net/kalebjimenez/"><img src={urlImgBehance} alt=""/></a>
          <a class="ml-3" href="https://www.linkedin.com/in/kalebjimenez/"><img src={urlImgLinkedin} alt=""/></a>
          <button class="ml-3 rounded-sm border border-white text-white font-normal px-2 text-sm hover:bg-white hover:text-gold transition-colors duration-500">Resume</button>
        </p>
      </div>
  </section>
)


export default SectionContact