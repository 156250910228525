import React from "react"

const WorkItem = (props) => (
  <div class="flex flex-col sm:flex-row justify-between mb-16 lg:mb-32 last:mb-0">
    <img class="m-0 sm:w-1/2 shadow-xl" src={props.imgUrl} alt=""/>
    <div class="sm:w-1/2 sm:pl-10 mt-5 sm:mt-0 flex flex-col justify-end items-start">
      <h3 class="font-body text-4xl text-gray mb-1">{props.title}</h3>
      <h4 class="font-body text-2xl mb-3">{props.subtitle}</h4>
      <p class="mb-3">{props.tags}</p>
      <button class="font-body bg-primary rounded-sm py-2 px-4 text-sm text-white">Look further</button>
    </div>
  </div>
)


export default WorkItem