import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MainHero from "../components/mainHero"
import SectionAbout from "../components/sectionAbout"
import SectionActivities from "../components/sectionActivities"
import SectionProcess from "../components/sectionProcess"
import SectionWork from "../components/sectionWork"
import SectionContact from "../components/sectionContact"


const IndexPage = () => (
  <Layout>
    <SEO title="Kaleb Jimenez - UX/UI Designer" />
    <MainHero/>
    <SectionAbout/>
    <SectionActivities/>
    <SectionProcess/>
    <SectionWork/>
    <SectionContact/>
  </Layout>
)

export default IndexPage
