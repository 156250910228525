import React from "react"

import process from "../images/process.svg"


const SectionProcess = () => (
  <section id="process" class="bg-orange-gray py-16 lg:py-40" >
    <div style={{
      margin: `0 auto`,
      maxWidth: 1066
    }}>
      <h2 class="text-center text-2xl lg:text-4xl mb-16">How I tackle design problems</h2>
      <img class="px-10" src={process} alt=""/>
    </div>
  </section>
)


export default SectionProcess