import React from "react"

import ActivityItem from "./activityItem"

import activityUx from "../images/activity-ux.svg"
import activityUi from "../images/activity-ui.svg"
import activityDevelopment from "../images/activity-development.svg"
import activityPrototype from "../images/activity-prototyping.svg"


const SectionActivities = () => (
  <section style={{
    margin: `0 auto`,
    maxWidth: 977
  }}>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-16 lg:mb-40 px-5">
      <div class="flex justify-center items-center lg:max-w-xxs">
        <h2 class="text-4xl text-center lg:text-left lg:text-6xl mb-0">What I love to do</h2>
      </div>
      <div class="grid grid-cols-2 grid-rows-2 gap-4">
        <ActivityItem activityTitle="UX Design" imgUrl={activityUx}/>
        <ActivityItem activityTitle="Prototyping" imgUrl={activityPrototype}/>
        <ActivityItem activityTitle="Development" imgUrl={activityDevelopment}/>
        <ActivityItem activityTitle="UI Design" imgUrl={activityUi}/>
      </div>
    </div>
  </section>
)


export default SectionActivities