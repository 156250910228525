import React from "react"

const SectionAbout = () => (
  <section id="about" style={{
    margin: `0 auto`,
    maxWidth: 1066
  }}>
      <div class="max-w-lg pt-8 mb-16 lg:mb-40 px-5">
        <h2 class="text-2xl">A little something about me…</h2>
        <p>I have been in the digital arena for more than 8 years working on a wide range of companies from ground breaking startups to companies leaders on their fields.</p>
        <p>My experience encompass working with a wide range of team sizes and methodologies. I work everyday not only to do Product Design that is profitable but also ethical and kind.</p>
        <p>In my free time you can catch me reading a book, cooking a new dish or playing the latest RPG.</p>
      </div>
  </section>
)


export default SectionAbout