import React from "react"

const MainHero = () => (
    <section style={{
        margin: `0 auto`,
        maxWidth: 1066
      }}>
    <div class="max-w-3xl mb-8 lg:mb-40 px-5">
        <h1 class="text-4xl lg:text-6xl">Hi! I'm Kaleb Jimenez, <br/>a UI/UX Designer working at in Saint Louis</h1>
        <h2 class="font-body font-hairline text-xl lg:text-hero-subtitle">I turn my design knowledge and passion into meaningful digital products that meet both user needs and business goals</h2>
    </div>
    </section>
)


export default MainHero