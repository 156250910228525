import React from "react"

const ActivityItem = (props) => (
  <div class="flex flex-col justify-center items-center">
    <img class="mb-5 w-auto shadow-xl" src={props.imgUrl} alt=""/>
    <p class="text-center text-xl font-bold text-gray">{props.activityTitle}</p>
  </div>
)


export default ActivityItem