import React from "react"

import WorkItem from "./workItem"

import urlImgCarengo from "../images/thumb-work-carengo.jpg"
import urlImgInmuebles from "../images/thumb-work-inmuebles.jpg"
import urlImgFutboleno from "../images/thumb-work-futboleno.jpg"

const SectionWork = () => (
  <section id="work" class="py-16 lg:py-40 px-5">
    <div style={{
          margin: `0 auto`,
          maxWidth: 896
        }}>
      <h2 class=" text-2xl lg:text-4xl text-center mb-16">Some of my work</h2>
      <WorkItem title="Carengo" subtitle="Mexico's Turo" tags="UI Design | Web App" imgUrl={urlImgCarengo} />
      <WorkItem title="Inmuebles24" subtitle="Changing the way to Rent" tags="UI Design | Web App" imgUrl={urlImgInmuebles} />
      <WorkItem title="Futboleno" subtitle="For true soccer fans" tags="UI Design | Web App" imgUrl={urlImgFutboleno} />
    </div>
      
  </section>
)


export default SectionWork